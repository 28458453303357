import React, {useEffect, useRef} from 'react';
import "./About.css";

function App_About() {

    return (
        <div id="Main_block__AboutBlock">
            <div id="Content_block_1__AboutBlock">
                <a name="About" className="Link__AboutBlock"></a>
                <div id="Heading__AboutBlock">О группе кампаний UniSafe</div>

                <div id="Border_block_00__AboutBlock"></div>
                <div id="Border_block_0__AboutBlock"></div>
                <div id="Border_block_1__AboutBlock"></div>
                <div id="Border_block_2__AboutBlock"></div>
                <div id="Border_block_3__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_start_1__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_start_2__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_top_left__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_top_right__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_group_1__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_group_2__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_group_3__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_group_4__AboutBlock"></div>
                <div className="Pointer__AboutBlock" id="Pointer_group_5__AboutBlock"></div>

                <div id="Text_description__AboutBlock">
                    <p>Наша цель - предоставить каждому клиенту высокий уровень сервиса как в бытовой сфере, так и в
                        сфере бизнеса</p>
                </div>

                <div id="Infographics__AboutBlock">
                    <div className="Infographics_block__AboutBlock">
                        <h1>c 2013</h1>
                        <p>работают наши сервисы</p>
                    </div>

                    <div className="Pointer__AboutBlock" id="Pointer_infographics_1__AboutBlock"></div>

                    <div className="Infographics_block__AboutBlock">
                        <h1>3 000 000+</h1>
                        <p>клиентов</p>
                    </div>

                    <div className="Pointer__AboutBlock" id="Pointer_infographics_2__AboutBlock"></div>

                    <div className="Infographics_block__AboutBlock">
                        <h1>47</h1>
                        <p>федеральных партнеров</p>
                    </div>
                </div>

                <div id="Text_main__AboutBlock">
                    <p>В своей работе мы используем исключительно эффективные и надежные решения</p>
                    <br></br>
                    <p>Все наше оборудование соответствует самым высоким требованиям безопасности.</p>
                    <br></br>
                    <p>Наши сотрудники прошли всю необходимую подготовку и постоянно повышают свой профессиональный
                        уровень. В своих решениях мы опираемся на мировой опыт в области охраны личных вещей. Что
                        позволяет нам поддерживать исключительно высокое качество сервиса!</p>
                    <br></br>
                    <p>Мы применяем индивидуальный подход для каждого клиента, благодаря чему наши клиенты получают
                        эффективную помощь и чувствуют реальную выгоду. В России наши продукты уже продают: Связной, ОТП
                        Банк, МТС, АТБанк, Softkey, Телефон.ру</p>
                    <br></br>
                    <p>Успешно реализовано более 12 технологичных сервисов</p>
                </div>
            </div>
        </div>
    )
}


export default App_About;