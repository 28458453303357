import React, {useEffect, useRef} from 'react';
import "./Block_1.css";
import AntiTheft from "../Media/Imagine_Products/AntiTheft.jpeg";


function App_1({headingText, Price, Link}) {

    return (
        <div className="Main_block__General" >
            <div className="Content_block__General" id="Content_block_1__BlockType1">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_TR__General" id="Border_block_1__BlockType1"></div>
                <div className="Border_block_RB__General" id="Border_block_2__BlockType1"></div>
                <div className="Border_block_LT__General" id="Border_block_3__BlockType1"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType1"></div>
                <div id="Pointer_group__BlockType1">
                    <div className="Pointer__General Pointer_group_1__General"></div>
                    <div className="Pointer__General Pointer_group_2__General"></div>
                    <div className="Pointer__General Pointer_group_3__General"></div>
                    <div className="Pointer__General Pointer_group_4__General"></div>
                    <div className="Pointer__General Pointer_group_5__General"></div>
                </div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType1"></div>

                <div id="Image__BlockType1">
                    <img src={AntiTheft}/>
                </div>

                <div className="Text_description__General" id="Text_description__BlockType1">
                    <p>Технологичный сервис, обеспечивающий возврат утраченного гаджета или компенсацию его
                        стоимости</p>
                </div>

                <div className="Buy__General" id="Buy__BlockType1">
                    <h1>Приобрести</h1>
                    <p>{Price}</p>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType1">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}



export default App_1;