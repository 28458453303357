import React, {useEffect, useRef} from 'react';
import "./Block_10.css";

function App_10({headingText, Price}) {

    return (
        <div className="Main_block__General" >
            <div className="Content_block__General" id="Content_block_1__BlockType10">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_TR__General" id="Border_block_1__BlockType10"></div>
                <div className="Border_block_RB__General" id="Border_block_2__BlockType10"></div>
                <div className="Border_block_LT__General" id="Border_block_3__BlockType10"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType10"></div>
                <div id="Pointer_group__BlockType10">
                    <div className="Pointer__General Pointer_group_1__General"></div>
                    <div className="Pointer__General Pointer_group_2__General"></div>
                    <div className="Pointer__General Pointer_group_3__General"></div>
                    <div className="Pointer__General Pointer_group_4__General"></div>
                    <div className="Pointer__General Pointer_group_5__General"></div>
                </div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType10"></div>

                <div className="Subtitle__General" id="Subtitle__BlockType10">
                    <p>Сервис возврата утраченных личных вещей</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType10">
                    <p>Как это работает</p>
                    <ul className="List__General">
                        <li>
                            Клиент получает пакет наклеек и бирок с индивидуальными номерами
                        </li>
                        <li>
                            Устанавливает наклейки и бирки на личные вещи (гаджеты, часы, ключи, документы)
                        </li>
                    </ul>
                    <br></br>
                    <p>В случае утраты вещей, служба возврата Юнисейф:</p>
                    <ul className="List__General">
                        <li>
                            Проводит разыскные мероприятия
                        </li>
                        <li>
                            Нашедший видит предложение вернуть за вознаграждение на наклейке или бирке
                        </li>
                    </ul>
                    <br></br>
                    <p>Если личные вещи утрачены безвозвратно:</p>
                    <ul className="List__General">
                        <li>
                            Клиент получает возмещение
                        </li>
                    </ul>
                </div>

                <div id="NB__BlockType10">
                    В 78% случаях владелец получает утраченные личные вещи
                </div>

                <div className="Buy__General" id="Buy__BlockType10">
                    <h1>Приобрести</h1>
                    <p>{Price[0]}</p>
                    <p>{Price[1]}</p>
                </div>
            </div>
        </div>
    )
}


export default App_10;