import React, {useEffect, useRef} from 'react';
import "./Contacts.css";

function App_Contacts() {

    return (
        <div id="Main_block__ContactsBlock" >
            <div id="Content_block_1__ContactsBlock">
                <a name="Contacts" className="Link__ContactsBlock"></a>
                <div id="Heading__ContactsBlock">Контакты</div>

                <div id="Border_block_1__ContactsBlock"></div>
                <div id="Border_block_2__ContactsBlock"></div>

                <div className="Pointer__ContactsBlock" id="Pointer_top_1__ContactsBlock"></div>
                <div className="Pointer__ContactsBlock" id="Pointer_top_2__ContactsBlock"></div>
                <div className="Pointer__ContactsBlock" id="Pointer_top_3__ContactsBlock"></div>

                <div id="Contacts__ContactsBlock">
                    <div>
                        <a href="tel:88005557005">+7 (985) 854-57-40</a>
                        <p>Телефон для связи</p>
                    </div>

                    <div>
                        <a href="mailto:help@usafe.ru">kollerov@usafe.ru</a>
                        <p>Электронная почта</p>
                    </div>

                    <div>
                        <a href="https://yandex.ru/maps/-/CDVfjV1p">г. Москва, Филевский б-р, д. 6</a>
                        <p>Адрес</p>
                    </div>

                    <div id="Map__ContactsBlock">
                        <iframe
                            src="https://yandex.ru/map-widget/v1/?ll=37.489355%2C55.764947&mode=whatshere&whatshere%5Bpoint%5D=37.489108%2C55.764986&whatshere%5Bzoom%5D=17&z=18.02"></iframe>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default App_Contacts;