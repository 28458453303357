import React, {useEffect, useRef} from 'react';
import "./Start_Block.css";
import Gradient from "../Media/Gradient.svg"



function Start_Block() {

    return (
        <div id="Gradient_Block">

            <img src={Gradient} id="Gradient"/>
            <div id="Gradient_text">
                <h1>Технологические</h1>
                <h2>сервисы помощи</h2>
                <p>на каждый день</p>
            </div>
            <a name="Servises" className="Link__StartBlock"></a>
        </div>
    )
}

export default Start_Block;