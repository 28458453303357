import './App.css';
import "./Product_blocks/General.css";
import "./Product_blocks/General_middle.css";
import Squares from "./Background/Squares";
import Header from "./Background/Header"
import Start_Block from "./Background/Start_Block";
import Block_1 from "./Product_blocks/Block_1";
import Block_2 from "./Product_blocks/Block_2";
// import Block_3 from "./Product_blocks/Block_3";
import Block_4 from "./Product_blocks/Block_4";
import Block_5 from "./Product_blocks/Block_5";
import Block_6 from "./Product_blocks/Block_6";
import Block_7 from "./Product_blocks/Block_7";
import Block_8 from "./Product_blocks/Block_8";
import Block_9 from "./Product_blocks/Block_9";
import Block_10 from "./Product_blocks/Block_10";
import Block_11 from "./Product_blocks/Block_11";
import Block_12 from "./Product_blocks/Block_12";
import Block_13 from "./Product_blocks/Block_13";
import Block_14 from "./Product_blocks/Block_14";
import Block_15 from "./Product_blocks/Block_15";
import About from "./Info_blocks/About";
import Partners from "./Info_blocks/Partners";
import Contacts from "./Info_blocks/Contacts";
import End from "./Info_blocks/End";

function App() {

    const headingText1 = "Антивор";
    const Link_1 = "http://avor.usafe.ru/";
    const Price_1 = "2900 р/год";

    const headingText2 = "Protection Station";
    const Link_2 = "http://ps.usafe.ru/";

    // const headingText3 = "UniSafe Антивор";

    const headingText4 = "Cyber Protection";
    const Price_4 = ["390 р/мес", "3900 р/год"];

    const headingText5 = "Kids Protection";
    const Link_5 = "https://usafe-kids.ru/";
    const Price_5 = ["190 р/мес", "1900 р/год"];

    const headingText6 = "Late protection";
    const Link_6 = "https://ltpt.ru/";

    const headingText7 = "Hi Tech Protection";
    const Link_7 = "http://ht.usafe.ru/";
    const Price_7 = "От 550 р/мес";

    const headingText8 = "Технология продаж";

    const headingText9 = "UniCare";
    const Link_9 = "https://care-usafe.ru/";
    const Price_9 = ["180 р/мес", "1800 р/год"];

    const headingText10 = "Сохрани дорогое";
    const Price_10 = ["190 р/мес", "1900 р/год"];

    const headingText11 = "UniCare+";
    const Price_11 = ["190 р/мес", "1900 р/год"];

    const headingText12 = "A-lock";
    const Link_12 = "http://al.usafe.ru/";
    const Price_12 = ["90 р/мес", "900 р/год"];

    const headingText13 = "UniSafe Smart";
    const Link_13 = "http://smart.usafe.ru/";

    const headingText14 = "Clover Money";
    const Link_14 = "http://clovermoney.usafe.ru/";

    const headingText15 = "Unisafe Tech";
    const Link_15 = "https://tech.usafe.ru/";


    return (
        <div className="App">
            <header>
                <Header/>
            </header>

            <body id="Body">

            <Squares/>
            <Start_Block/>

            <Block_1 headingText={headingText1} Link={Link_1} Price={Price_1} />
            <Block_2 headingText={headingText2} Link={Link_2} />
            {/*<Block_3 headingText={headingText3} />*/}
            <Block_4 headingText={headingText4} Price={Price_4} />
            <Block_5 headingText={headingText5} Link={Link_5} Price={Price_5}/>
            <Block_6 headingText={headingText6} Link={Link_6} />
            <Block_7 headingText={headingText7} Link={Link_7} Price={Price_7} />
            <Block_8 headingText={headingText8} />
            <Block_9 headingText={headingText9} Link={Link_9} Price={Price_9} />
            <Block_10 headingText={headingText10} Price={Price_10} />
            <Block_11 headingText={headingText11} Price={Price_11} />
            <Block_12 headingText={headingText12} Link={Link_12} Price={Price_12} />
            <Block_13 headingText={headingText13} Link={Link_13} />
            <Block_14 headingText={headingText14} Link={Link_14} />
            <Block_15 headingText={headingText15} Link={Link_15} />

            <About />
            <Partners />
            <Contacts />
            <End />


            </body>
        </div>
    );
}

export default App;
