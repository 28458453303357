import React, {useEffect, useRef} from 'react';
import "./Block_8.css";

function App_8({headingText}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType8">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_TR__General" id="Border_block_1__BlockType8"></div>
                <div className="Border_block_RB__General" id="Border_block_2__BlockType8"></div>
                <div className="Border_block_BL__General" id="Border_block_3__BlockType8"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType8"></div>
                <div id="Pointer_group__BlockType8">
                    <div className="Pointer__General Pointer_group_1__General"></div>
                    <div className="Pointer__General Pointer_group_2__General"></div>
                    <div className="Pointer__General Pointer_group_3__General"></div>
                    <div className="Pointer__General Pointer_group_4__General"></div>
                    <div className="Pointer__General Pointer_group_5__General"></div>
                </div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType8"></div>

                <div className="Subtitle__General" id="Subtitle__BlockType8">
                    <p>Сервис технологии продаж</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType8">
                    <p>Максимально простой процесс оплаты и оформления. Персональная адаптация алгоритма работы.</p>
                    <ul className="List__General">
                        <li>
                            Виртуальная технология
                            <ul className="List__General">
                                <li>
                                    Клиенту предоставляется лицензионный ключ
                                </li>
                                <li>
                                    Служба поддержки клиентов 24/7 поможет пройти процесс установки вместе с клиентом и
                                    ответит на все вопросы
                                </li>
                            </ul>
                        </li>
                        <li>
                            Коробочная технология
                            <ul className="List__General">
                                <li>
                                    Клиенту предоставляется полиграфическая коробка с инструкциями по установке и
                                    лицензионным соглашением
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}


export default App_8;