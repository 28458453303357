import React, {useEffect, useRef} from 'react';
import "./End.css";

function App_End() {

    return (
        <div id="Main_block__EndBlock" >
            <div id="Content_block_1__EndBlock">
                <div id="Border_block_1__EndBlock"></div>

                <div id="String__EndBlock">
                    <p>© 2013 — 2024 ООО «ЮниСейф» 121601, г. Москва, Филевский бульвар, д. 6</p>
                </div>
            </div>
        </div>
    )
}

export default App_End;