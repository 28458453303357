import "./Block_13.css";
import React from "react";
import UniCare from "../Media/Imagine_Products/UniSafeSmart.jpeg"


function App_13({headingText, Link}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType13">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_BL__General" id="Border_block_1__BlockType13"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType13"></div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType13"></div>

                <div id="Image__BlockType13">
                    <img src={UniCare}/>
                </div>

                <div className="Subtitle__General" id="Subtitle__BlockType13">
                    <p>Услуга по настройке умного дома</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType13">
                    <p>Максимально простой процесс оформления и оплаты. Подбираем персональные условия для каждого клиента</p>
                    <ul className="List__General">
                        <li>
                            <p style={{fontWeight: "bold"}}>Дистанционная настройка</p>
                            <p>Удаленное подключение для настройки вашего умного дома когда вам угодно</p>
                        </li>
                        <li>
                            <p style={{fontWeight: "bold"}}>Выездная настройка</p>
                            <p>Наш специалист прибудет к вам домой для настройки умного дома</p>
                        </li>
                    </ul>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType13">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_13;