import "./Block_9.css";
import React from "react";
import UniCare from "../Media/Imagine_Products/UniCare.JPG"


function App_9({headingText, Price, Link}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType9">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_BL__General" id="Border_block_1__BlockType9"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType9"></div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType9"></div>

                <div id="Image__BlockType9">
                    <img src={UniCare}/>
                </div>

                <div className="Subtitle__General" id="Subtitle__BlockType9">
                    <p>Решение сложностей, связанных с цифровой техникой 24/7</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType9">
                    <p>Включенные сервисы для: смартфонов, планшетов, ноутбуков, стационарных компьютеров</p>
                    <ul className="List__General">
                        <li>
                            Активация и перенос данных
                        </li>
                        <li>
                            Установка и настройка любого программного обеспечения
                        </li>
                        <li>
                            Устранение вирусов и вредоносного ПО
                        </li>
                    </ul>
                </div>

                <div className="Buy__General" id="Buy__BlockType9">
                    <h1>Приобрести</h1>
                    <p>{Price[0]}</p>
                    <p>{Price[1]}</p>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType9">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_9;