import React, {useEffect, useRef} from 'react';
import "./Block_6.css";
import LateProtection from "../Media/Imagine_Products/LateProtection.jpeg"


function App_6({headingText, Link}) {

    return (
        <div className="Main_block__General" >
            <div className="Content_block__General" id="Content_block_1__BlockType6">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_TR__General" id="Border_block_1__BlockType6"></div>
                <div className="Border_block_RB__General" id="Border_block_2__BlockType6"></div>
                <div className="Border_block_LT__General" id="Border_block_3__BlockType6"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType6"></div>
                <div id="Pointer_group__BlockType6">
                    <div className="Pointer__General Pointer_group_1__General"></div>
                    <div className="Pointer__General Pointer_group_2__General"></div>
                    <div className="Pointer__General Pointer_group_3__General"></div>
                    <div className="Pointer__General Pointer_group_4__General"></div>
                    <div className="Pointer__General Pointer_group_5__General"></div>
                </div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType6"></div>

                <div id="Image__BlockType6">
                    <img src={LateProtection}/>
                </div>

                <div className="Subtitle__General" id="Subtitle__BlockType6">
                    <p>Сохрани финансы, время и деньги</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType6">
                    <p>Выплата долга в течение промежутка времени в будущем. В случае непредвиденных обстоятельств,
                        услуга «Late protection» берет обязанность по уплате пени при просрочке платежа.</p>
                </div>

                <div className="Buy__General" id="Buy__BlockType6">
                    <h1>Приобрести</h1>
                    <p>от 1000 р/мес</p>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType6">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_6;