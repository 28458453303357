import "./Block_15.css";
import React from "react";


function App_15({headingText, Link}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType15">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_BL__General" id="Border_block_1__BlockType15"></div>
                <div className="Border_block_TR__General" id="Border_block_2__BlockType15"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType15"></div>
                <div className="Pointer__General" id="Pointer_bottom_1__BlockType15"></div>
                <div className="Pointer__General" id="Pointer_bottom_2__BlockType15"></div>

                <div className="Subtitle__General" id="Subtitle__BlockType15">
                    <p>Разработка программного обеспечения</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType15">
                    <p>Мы предлагаем Вам собрать техническую команду на основе наших специалистов на время разработки и поддержки вашего сайта, приложения или программы. Данный подход позволяет Вам оперативно построить гибкий отдел разработки, максимально сократив Ваши расходы. Мы воплощаем, развиваем и совершенствуем ваши идеи, добиваемся максимального результата.</p>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType15">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_15;