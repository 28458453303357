import "./Block_12.css";
import ALock from "../Media/Imagine_Products/ALock.jpeg"
import React from "react";


function App_12({headingText, Price, Link}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType12">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_TR__General" id="Border_block_1__BlockType12"></div>
                <div className="Border_block_RB__General" id="Border_block_2__BlockType12"></div>
                <div className="Border_block_Line__General" id="Border_block_3__BlockType12"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType12"></div>
                <div id="Pointer_group__BlockType12">
                    <div className="Pointer__General Pointer_group_1__General"></div>
                    <div className="Pointer__General Pointer_group_2__General"></div>
                    <div className="Pointer__General Pointer_group_3__General"></div>
                    <div className="Pointer__General Pointer_group_4__General"></div>
                    <div className="Pointer__General Pointer_group_5__General"></div>
                </div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType12"></div>

                <div id="Image__BlockType12">
                    <img src={ALock}/>
                </div>

                <div className="Subtitle__General" id="Subtitle__BlockType12">
                    <p>Блокировщик рекламы</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType12">
                    <p>Комплексное решение для контроля контента</p>
                    <ul className="List__General">
                        <li>
                            Блокирует рекламу в приложения и браузерах
                        </li>
                        <li>
                            Экономит заряд батареи
                        </li>
                        <li>
                            Экономит трафик мобильного интернета
                        </li>
                        <li>
                            Защищает личные данные
                        </li>
                    </ul>
                </div>

                <div className="Buy__General" id="Buy__BlockType12">
                    <h1>Приобрести</h1>
                    <p>{Price[0]}</p>
                    <p>{Price[1]}</p>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType12">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_12;