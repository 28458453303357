import React, {useEffect, useRef} from 'react';
import "./Partners.css";
import Logo_MTS from "../Media/Logo_partners/Logo_MTS.svg"
import Logo_MegaFon from "../Media/Logo_partners/Logo_MegaFon.svg"
import Logo_Beeline from "../Media/Logo_partners/Logo_Beeline.svg"
import Logo_Tele2 from "../Media/Logo_partners/Logo_Tele2.svg"
import Logo_Sber from "../Media/Logo_partners/Logo_Sber.svg"
import Logo_Alfa from "../Media/Logo_partners/Logo_Alfa.svg"
import Logo_ATB from "../Media/Logo_partners/Logo_ATB.svg"
import Logo_OTP from "../Media/Logo_partners/Logo_OTP.svg"
import Logo_MVideo from "../Media/Logo_partners/Logo_MVideo.svg"
import Logo_Eldorado from "../Media/Logo_partners/Logo_Eldorado.svg"
import Logo_Mechta from "../Media/Logo_partners/Logo_Mechta.svg"
import Logo_Evrika from "../Media/Logo_partners/Logo_Evrika.svg"


function App_Partners() {

    return (
        <div id="Main_block__PartnersBlock" >
            <div id="Content_block_1__PartnersBlock">
                <div id="Heading__PartnersBlock">Партнеры</div>

                <div id="Border_block_1__PartnersBlock"></div>
                <div id="Border_block_2__PartnersBlock"></div>
                <div className="Pointer__PartnersBlock" id="Pointer_top_left__PartnersBlock"></div>
                <div className="Pointer__PartnersBlock" id="Pointer_top_right__PartnersBlock"></div>
                <div className="Pointer__PartnersBlock" id="Pointer_group_1__PartnersBlock"></div>
                <div className="Pointer__PartnersBlock" id="Pointer_group_2__PartnersBlock"></div>
                <div className="Pointer__PartnersBlock" id="Pointer_group_3__PartnersBlock"></div>
                <div className="Pointer__PartnersBlock" id="Pointer_group_4__PartnersBlock"></div>
                <div className="Pointer__PartnersBlock" id="Pointer_group_5__PartnersBlock"></div>

                <div id="Partners__PartnersBlock">
                    <div id="Partners_flex__PartnersBlock">
                        <div className="Partners_line__PartnersBlock">
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_MTS}/>
                            </div>
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_MegaFon}/>
                            </div>
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_Beeline}/>
                            </div>
                        </div>
                        <div className="Partners_line__PartnersBlock">
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_Tele2}/>
                            </div>
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_Sber}/>
                            </div>
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_Alfa}/>
                            </div>
                        </div>
                        <div className="Partners_line__PartnersBlock">
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_ATB}/>
                            </div>
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_OTP}/>
                            </div>
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_MVideo}/>
                            </div>
                        </div>
                        <div className="Partners_line__PartnersBlock">
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_Eldorado}/>
                            </div>
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_Mechta}/>
                            </div>
                            <div className="Partners_block__PartnersBlock">
                                <div></div>
                                <img src={Logo_Evrika}/>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}



export default App_Partners;