import React from "react";
import "./Block_2.css";
import "./General.css";
import ProtectionStation from "../Media/Imagine_Products/ProtectionStation.jpeg"


function App_2({headingText, Link}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType2">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_BL__General" id="Border_block_1__BlockType2"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType2"></div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType2"></div>

                <div id="Image__BlockType2">
                    <img src={ProtectionStation}/>
                </div>

                <div className="Subtitle__General" id="Subtitle__BlockType2">
                    <p>Станция бронирования экрана телефона</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType2">
                    <ul className="List__General">
                        <li>
                            47 Федеральных партнеров
                        </li>
                        <li>
                            Более 3 млн клиентов
                        </li>
                        <li>
                            Точность 1 мкм
                        </li>
                        <li>
                            Собственное ПО
                        </li>
                        <li>
                            Поддержка 24 часа
                        </li>
                        <li>
                            Заказ шаблона от 2 до 10 минут
                        </li>
                        <li>
                            Более 10 тыс. лекал
                        </li>
                        <li>
                            Обучение и доставка расходников
                        </li>
                    </ul>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType2">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_2;