import "./Block_11.css";
import UniCarePlus from "../Media/Imagine_Products/UniCare+.jpeg"
import React from "react";


function App_11({headingText, Price}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType11">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_BL__General" id="Border_block_1__BlockType11"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType11"></div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType11"></div>

                <div id="Image__BlockType11">
                    <img src={UniCarePlus}/>
                </div>

                <div className="Subtitle__General" id="Subtitle__BlockType11">
                    <p>Решение всех бытовых сложностей 24/7</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType11">
                    <p>Включенные сервисы - UniCare+ помощи по дому:</p>
                    <ul className="List__General">
                        <li>
                            Сантехнические работы
                        </li>
                        <li>
                            Электромонтажные работы
                        </li>
                        <li>
                            Мастер на час
                        </li>
                        <li>
                            Слесарные работы
                        </li>
                    </ul>
                </div>

                <div className="Buy__General" id="Buy__BlockType11">
                    <h1>Приобрести</h1>
                    <p>{Price[0]}</p>
                    <p>{Price[1]}</p>
                </div>
            </div>
        </div>
    )
}


export default App_11;