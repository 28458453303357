import "./Block_7.css";
import HiTechProtection from "../Media/Imagine_Products/HiTechProtection.jpeg"
import React from "react";


function App_7({headingText, Price, Link}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType7">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_BL__General" id="Border_block_1__BlockType7"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType7"></div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType7"></div>

                <div id="Image__BlockType7">
                    <img src={HiTechProtection}/>
                </div>

                <div className="Subtitle__General" id="Subtitle__BlockType7">
                    <p>Технологичный сервис, защита гаджетов</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType7">
                    <p>Как это работает</p>
                    <ul className="List__General">
                        <li>
                            Клиент оплачивает и получает ключ активации в салоне связи
                        </li>
                        <li>
                            В удобное время дистанционно активирует услугу
                        </li>
                        <li>
                            В течении 2-ух дней после принятия решения гаджет ремонтируют или выплачивают возмещение в случае, если гаджет невозможно восстановить
                        </li>
                    </ul>
                </div>

                <div className="Buy__General" id="Buy__BlockType7">
                    <h1>Приобрести</h1>
                    <p>{Price}</p>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType7">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_7;