import "./Block_5.css";
import React from "react";
import KidsProtection from "../Media/Imagine_Products/KidsProtection.jpeg"


function App_5({headingText, Price, Link}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType5">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_BL__General" id="Border_block_1__BlockType5"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType5"></div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType5"></div>

                <div id="Image__BlockType5">
                    <img src={KidsProtection}/>
                </div>

                <div className="Text_description__General" id="Text_description__BlockType5">
                    <p>Защищает ребёнка в непредвиденных ситуациях и предотвращение вреда от использования гаджетов</p>
                </div>

                <div className="Buy__General" id="Buy__BlockType5">
                    <h1>Приобрести</h1>
                    <p>{Price[0]}</p>
                    <p>{Price[1]}</p>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType5">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_5;