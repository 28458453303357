import React, {useEffect, useRef} from 'react';
import "./Block_14.css";

function App_14({headingText, Link}) {

    return (
        <div className="Main_block__General" >
            <div className="Content_block__General" id="Content_block_1__BlockType14">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_TR__General" id="Border_block_1__BlockType14"></div>
                <div className="Border_block_RB__General" id="Border_block_2__BlockType14"></div>
                <div className="Border_block_LT__General" id="Border_block_3__BlockType14"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType14"></div>
                <div id="Pointer_group__BlockType14">
                    <div className="Pointer__General Pointer_group_1__General"></div>
                    <div className="Pointer__General Pointer_group_2__General"></div>
                    <div className="Pointer__General Pointer_group_3__General"></div>
                    <div className="Pointer__General Pointer_group_4__General"></div>
                    <div className="Pointer__General Pointer_group_5__General"></div>
                </div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType14"></div>

                <div className="Text_description__General" id="Text_description__BlockType14">
                    <p>Умные решения финансовых вопросов</p>
                    <p>Экономия при продлении страхования ипотеки Сбербанка в 3 клика</p>
                    <p>Выгода до 40%!</p>
                </div>

                <div className="Button_site__General" id="Button_site__BlockType14">
                    <a href={Link}>
                        <p>Перейти на сайт</p>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default App_14;