import React, {useEffect, useRef} from 'react';
import "./Header.css";
import Logo_US from "../Media/Logo_partners/Logo_US.svg"
import Logo_US_unofficial from "../Media/Logo_partners/Logo_US_unofficial.svg"

function App_Header() {

    return (
        <div id="Header_body">
            <div id="Header">
                <img src={Logo_US_unofficial} id="Logo_US_unofficial"/>
                {/*<img src={Logo_US} id="Logo_s"/>*/}
                {/*<p id="Name_US">UniSafe</p>*/}
                <div id="Link_block">

                    <a href="#About" className="Link">
                        <div>
                            О нас
                        </div>
                    </a>

                    <a href="#Servises" className="Link">
                        <div>
                            Сервисы
                        </div>
                    </a>

                    <a href="#Contacts" className="Link">
                        <div>
                            Контакты
                        </div>
                    </a>

                    <a href="" className="Link">
                        <div>
                            Стать партнером
                        </div>
                    </a>

                    <a href="" className="Link">
                        <div>
                            Клиентам
                        </div>
                    </a>

                </div>
            </div>

            <div id="Header_border">
            </div>
        </div>
    )
}



export default App_Header;