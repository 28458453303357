import React, {useEffect, useRef} from 'react';
import "./Block_4.css";
import Logo_CyberPro from "../Media/Logo_partners/Logo_CyberPro.svg"


function App_4({headingText, Price}) {

    return (
        <div className="Main_block__General">
            <div className="Content_block__General" id="Content_block_1__BlockType4">
                <p className="Heading__General">{headingText}</p>

                <div className="Border_block_TR__General" id="Border_block_1__BlockType4"></div>
                <div className="Border_block_RB__General" id="Border_block_2__BlockType4"></div>
                <div className="Border_block_Line__General" id="Border_block_3__BlockType4"></div>

                <div className="Pointer__General" id="Pointer_top__BlockType4"></div>
                <div id="Pointer_group__BlockType4">
                    <div className="Pointer__General Pointer_group_1__General"></div>
                    <div className="Pointer__General Pointer_group_2__General"></div>
                    <div className="Pointer__General Pointer_group_3__General"></div>
                    <div className="Pointer__General Pointer_group_4__General"></div>
                    <div className="Pointer__General Pointer_group_5__General"></div>
                </div>
                <div className="Pointer__General" id="Pointer_bottom__BlockType4"></div>

                <div className="Subtitle__General"id="Subtitle__BlockType4">
                    <p>Новейшая система борьбы с вредоносными программами</p>
                </div>

                <div className="Text_body__General" id="Text_body__BlockType4">
                    <ul className="List__General">
                        <li>
                            Использование искусственного интеллекта для выявления вредоносного ПО
                        </li>
                        <li>
                            Минимальное использование ресурсов устройства
                        </li>
                        <li>
                            Предотвращение хищения денежный средств с использование программ агентов
                        </li>
                        <li>
                            Финансовая компенсация в случае нанесения вредя с применением вредоносного ПО
                        </li>
                    </ul>
                </div>


                <div className="Buy__General" id="Buy__BlockType4">
                    <div id="Buy_flex_block__BlockType4">
                        <div id="Logo__BlockType4">
                            <img src={Logo_CyberPro}/>
                        </div>
                        <div id="Buy_Text__BlockType4">
                            <h1>Приобрести</h1>
                            <p>{Price[0]}</p>
                            <p>{Price[1]}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default App_4;