import React, {useEffect, useRef} from 'react';
import "./Squares.css";

function App_2() {

    const Move_block_Ref = useRef(null);

    useEffect(() => {
        var Move_block = Move_block_Ref.current;

        //Задаем рандомные координаты
        if (Move_block) {
            var W = Move_block.offsetWidth;
            var H = Move_block.offsetHeight;
            var W1 = Move_block.offsetWidth;
            var H1 = Move_block.offsetHeight;

            var w = new Array(4);
            var h = new Array(4);

            for (var l = 1; l <= 4; l++) {
                var Block = document.getElementsByClassName("Block_type_" + l);

                w[l - 1] = Block[0].offsetWidth;
                h[l - 1] = Block[0].offsetHeight;
            }

            if (W < (w[0] * 2 + w[1] * 3 + w[2] * 4 + w[3] * 5 + 13 * 10)) {
                W = (w[0] * 2 + w[1] * 3 + w[2] * 4 + w[3] * 5 + 13 * 10);
            }
            console.log("ширина ", W, W1);

            if (H < (h[0] * 2 + h[1] * 3 + h[2] * 4 + h[3] * 5 + 13 * 10)) {
                H = (h[0] * 2 + h[1] * 3 + h[2] * 4 + h[3] * 5 + 13 * 10);
            }
            console.log("длина ", H, H1);


            var Block_coord_H = new Array(4);
            var Block_coord_W = new Array(4);

            for (var l = 1; l <= 4; l++) {
                Block = document.getElementsByClassName("Block_type_" + l);

                Block_coord_H[l - 1] = new Array(Math.pow((l + 1), 2));
                Block_coord_W[l - 1] = new Array(Math.pow((l + 1), 2));

                var k = 0;

                var Nx = l + 1;
                var Ny = l + 1;

                var Inter = 0;

                for (var i = 0; i <= l; i++) {
                    for (var j = 0; j <= l; j++) {
                        Block_coord_H[l - 1][k] = (Math.floor(Math.random() * (H / Ny - h[l - 1])) + (H / Ny) * j) / H;
                        Block_coord_W[l - 1][k] = (Math.floor(Math.random() * (W / Nx - w[l - 1])) + (W / Nx) * i) / W;

                        Inter = 0

                        for (var l_dop = 1; l_dop < l; l_dop++) {
                            var k_max = Block_coord_H[l_dop - 1].length;

                            for (var k_dop = 0; k_dop < k_max; k_dop++) {
                                var Dif_H = (Block_coord_H[l - 1][k] - Block_coord_H[l_dop - 1][k_dop]) * H;
                                var Dif_W = (Block_coord_W[l - 1][k] - Block_coord_W[l_dop - 1][k_dop]) * W;

                                if ((Dif_H > -h[l-1] - 10) && (Dif_H < h[l_dop-1] + 10) && (Dif_W > -w[l-1] - 10) && (Dif_W < w[l_dop-1] + 10)) {
                                    Inter++;
                                }
                            }
                        }

                        console.log(Inter)

                        if (Inter === 0) {
                            Block[k].style.top = Block_coord_H[l-1][k] * H1 + 'px';
                            Block[k].style.left = Block_coord_W[l-1][k] * W1 + 'px';
                            k++;
                        } else {
                            j--;
                        }


                    }
                }
            }

            //Пересчет координат при изменении размера окна
            window.addEventListener('resize', function() {
                console.log("Размер окна изменен");

                W = Move_block.offsetWidth;
                H = Move_block.offsetHeight;

                for (var l = 1; l <= 4; l++) {
                    var Block = document.getElementsByClassName("Block_type_" + l);
                    k = 0;

                    for (var i = 0; i <= l; i++) {
                        for (var j = 0; j <= l; j++) {
                            Block[k].style.top = Block_coord_H[l - 1][k] * H + 'px';
                            Block[k].style.left = Block_coord_W[l - 1][k] * W + 'px';
                            k++;
                        }
                    }
                }
            });
        }
    }, []);


    // const Move_block_Ref = useRef(null);

    //Отслеживаем положение мышки и рассчитываем движение блоков
    useEffect(() => {
        var Move_block = Move_block_Ref.current;

        if (Move_block) {
            var W = Move_block.offsetWidth;
            var H = Move_block.offsetHeight;

            var Layer_1 = document.getElementById("Layer_1");
            var Layer_2 = document.getElementById("Layer_2");
            var Layer_3 = document.getElementById("Layer_3");
            var Layer_4 = document.getElementById("Layer_4");

            function move(Layer) {

                var X = document.getElementById('X');
                var Y = document.getElementById('Y');

                function pos(e){
                    console.log("мышка сдвинулась")
                    X.value = e.pageX;
                    Y.value = e.pageY;

                    var y = Move_block.offsetTop;
                    var x = Move_block.offsetLeft;

                    y = y + H / 2;
                    x = x + W / 2;
                    var t = 0;

                    if (Layer === Layer_1) {
                        t = 0.2
                    }
                    if (Layer === Layer_2) {
                        t = 0.1
                    }
                    if (Layer === Layer_3) {
                        t = 0.05
                    }
                    if (Layer === Layer_4) {
                        t = 0.025
                    }


                    var easing = 'cubic-bezier(0.42, 0, 0.58, 1)';
                    var speedMultiplier = 2;
                    Layer.style.transition = `top ${speedMultiplier}s ${easing}, left ${speedMultiplier}s ${easing}`;

                    Layer.style.top = y - H / 2 + (Y.value - y - window.scrollY) * t + 'px';
                    Layer.style.left = x - W / 2 + (X.value - x) * t + 'px';
                }
                window.addEventListener('mousemove', pos, false);
            }
            move(Layer_1);
            move(Layer_2);
            move(Layer_3);
            move(Layer_4);
        }
    }, []);



    //Блюрим фон
    useEffect(() => {
        var Move_block = Move_block_Ref.current;

        if (Move_block) {
            var W = Move_block.offsetWidth;
            var H = Move_block.offsetHeight;

            var Layer_1 = document.getElementById("Layer_1");
            var Layer_2 = document.getElementById("Layer_2");
            var Layer_3 = document.getElementById("Layer_3");
            var Layer_4 = document.getElementById("Layer_4");

            var Block_type_1 = document.getElementsByClassName("Block_type_1");
            var Block_type_2 = document.getElementsByClassName("Block_type_2");
            var Block_type_3 = document.getElementsByClassName("Block_type_3");
            var Block_type_4 = document.getElementsByClassName("Block_type_4");

            var Opacity_min = 0.4

            function Blur(Layer) {

                var X = document.getElementById('X');
                var Y = document.getElementById('Y');

                    function f(e){

                    var Win_Height = window.innerHeight;
                    var Scroll = window.scrollY

                    var max_blur = 20;

                    if (Scroll < Win_Height/3) {
                        Layer.style.filter = "blur(0px)";
                        for (var i = 0; i < Block_type_1.length; i++) {
                            Block_type_1[i].style.backgroundColor = "rgb(5, 149, 60)";
                        }
                        for (var i = 0; i < Block_type_2.length; i++) {
                            Block_type_2[i].style.backgroundColor = "rgb(5, 149, 60)";
                        }
                        for (var i = 0; i < Block_type_3.length; i++) {
                            Block_type_3[i].style.backgroundColor = "rgb(5, 149, 60)";
                        }
                        for (var i = 0; i < Block_type_4.length; i++) {
                            Block_type_4[i].style.backgroundColor = "rgb(5, 149, 60)";
                        }
                    }

                    if ((Scroll >= Win_Height/3) && (Scroll < Win_Height * 4/3)) {
                        var blur = (Scroll - Win_Height/3) / (Win_Height) * max_blur;
                        Layer.style.filter = "blur(" + blur + "px)";
                        Layer.style.opacity = 1 - (Scroll - Win_Height/3) / (Win_Height) * (1 - Opacity_min);

                        var R = 5 + 144 * (Scroll - Win_Height/3) / Win_Height;
                        var G = 149;
                        var B = 60 + 85 * (Scroll - Win_Height/3) / Win_Height;
                        for (var i = 0; i < Block_type_1.length; i++) {
                            Block_type_1[i].style.backgroundColor = "rgb(" + R + ", " + G + ", " + B + ")";
                        }
                        for (var i = 0; i < Block_type_2.length; i++) {
                            Block_type_2[i].style.backgroundColor = "rgb(" + R + ", " + G + ", " + B + ")";
                        }
                        for (var i = 0; i < Block_type_3.length; i++) {
                            Block_type_3[i].style.backgroundColor = "rgb(" + R + ", " + G + ", " + B + ")";
                        }
                        for (var i = 0; i < Block_type_4.length; i++) {
                            Block_type_4[i].style.backgroundColor = "rgb(" + R + ", " + G + ", " + B + ")";
                        }

                    }

                    if (Scroll >= Win_Height * 4/3) {
                        Layer.style.filter = "blur(" + max_blur + "px)";
                        Layer.style.opacity = Opacity_min;
                        for (var i = 0; i < Block_type_1.length; i++) {
                            Block_type_1[i].style.backgroundColor = "rgb(149, 149, 149)";
                        }
                        for (var i = 0; i < Block_type_2.length; i++) {
                            Block_type_2[i].style.backgroundColor = "rgb(149, 149, 149)";
                        }
                        for (var i = 0; i < Block_type_3.length; i++) {
                            Block_type_3[i].style.backgroundColor = "rgb(149, 149, 149)";
                        }
                        for (var i = 0; i < Block_type_4.length; i++) {
                            Block_type_4[i].style.backgroundColor = "rgb(149, 149, 149)";
                        }
                    }

                }
                window.addEventListener('scroll', f, false);
            }
            Blur(Layer_1);
            Blur(Layer_2);
            Blur(Layer_3);
            Blur(Layer_4);
        }
    }, []);

    return (
        <div id="Body_Squares">
            <div id="Move_block" ref={Move_block_Ref}>
                <p type="text" id="X"></p>
                <p type="text" id="Y"></p>

                <div id="Layer_4" className="Layer">
                    {[...Array(25)].map((_, index) => (
                        <div key={index} id={`B_4_${index + 1}`} className="Block_type_4">
                            {/*{index + 1}*/}
                        </div>
                    ))}
                </div>

                <div id="Layer_3" className="Layer">
                    {[...Array(16)].map((_, index) => (
                        <div key={index} id={`B_3_${index + 1}`} className="Block_type_3">
                            {/*{index + 1}*/}
                        </div>
                    ))}
                </div>

                <div id="Layer_2" className="Layer">
                    {[...Array(9)].map((_, index) => (
                        <div key={index} id={`B_2_${index + 1}`} className="Block_type_2">
                            {/*{index + 1}*/}
                        </div>
                    ))}
                </div>

                <div id="Layer_1" className="Layer">
                    {[...Array(4)].map((_, index) => (
                        <div key={index} id={`B_1_${index + 1}`} className="Block_type_1">
                            {/*{index + 1}*/}
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );

}

export default App_2;